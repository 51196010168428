<template>
  <div class="container-fluid p-0">
    <div v-if="loading" id="loader" class="loader"></div>

    <div class="home-grad-cnt">
      <img
        :src="userTheme === 'dark-theme' ? darkImageHome : lightImageHome"
        draggable="false"
        class="blob-grad"
      />
    </div>

    <Navbar @toggle-moon="toggleMoon" />
    <section class="home">
      <h1 class="welcome ms-5">
        WELCOME<br />
        TO MEGO
      </h1>
      <div
        class="gif-cnt"
        :class="{
          'dark-blend-mode': userTheme === 'dark-theme',
          'light-blend-mode': userTheme === 'light-theme',
        }"
      >
        <img
          :src="userTheme === 'dark-theme' ? darkMegoGif : lightMegoGif"
          alt="mego"
          draggable="false"
        />
      </div>
      <div class="revol me-5">
        <h1>
          Create, manage, sell and <br />
          buy <span>NFT tickets </span> with ease!
        </h1>
        <a href="https://join.mego.tickets/" target="_blank">
          <button class="btn mt-3">JOIN MEGO</button>
        </a>
      </div>
    </section>
    <section class="demo">
      <h1>
        JOIN THE
        <span class="gradient-text">NFT tickets</span> <br />
        REVOLUTION!
      </h1>
      <p>Be part of the future event experiences.</p>
      <!--       <a href="https://demo.mego.cx/#/" target="_blank">
        <button class="btn">JOIN MEGO NOW</button>
      </a> -->
    </section>
    <UseMego />
    <section class="txt-alone blob-grad-cnt text-center">
      <div class="blob-grad"></div>
      <h1 class="px-5 m-0">
        Whether you're a creator or a user, <br />
        MEGO offers advantages for all!
      </h1>
    </section>
    <section class="creator-or-user">
      <div class="gradient-creator-cnt">
        <img
          src="../assets/img/gradient-creator2_comp.svg"
          draggable="false"
          :class="{
            'dark-opacity': userTheme === 'dark-theme',
            'light-opacity': userTheme === 'light-theme',
          }"
        />
      </div>

      <div class="creator-col px-5 mb-5 showDesk">
        <h1 class="text-center mb-5">
          ARE YOU<br />
          AN EVENT ORGANIZER?
        </h1>

        <div class="row pt-5">
          <div class="col box creator-box cr-1">
            <h1>01</h1>
            <p>Fill out the form to be accepted as an event organizer</p>
            <a href="/#/apply">
              <button class="btn">APPLY</button>
            </a>
          </div>
          <div class="col box creator-box cr-3">
            <h1>02</h1>
            <p>
              Wait for the confirmation e-mail from our team, in the mean while
              feel free to join discord.
            </p>
            <a href="https://discord.com/invite/PZAxKZkX9A" target="_blank">
              <button class="btn">DISCORD</button>
            </a>
          </div>
        </div>

        <div class="row">
          <div class="col box creator-box cr-3">
            <h1>03</h1>
            <p>
              Once accepted start creating your event using the admin panel.
            </p>
          </div>
          <div class="col box creator-box cr-4">
            <h1>04</h1>
            <p>Share the event!</p>
            <a href="mailto:info@mego.tickets" target="_blank">
              <button class="btn">ASK US TO SPONSOR THE EVENT</button>
            </a>
          </div>
        </div>
      </div>

      <div class="creator-col px-5 showDesk">
        <h1 class="text-center my-5">
          ARE YOU<br />
          A USER?
        </h1>

        <div class="row pt-5">
          <div class="col box creator-box cr-1">
            <h1>01</h1>
            <p>
              Sign up with your wallet or create an internal wallet using your
              email.
            </p>
          </div>
          <div class="col box creator-box cr-3">
            <h1>02</h1>
            <p>Buy tickets with your preferred payment method.</p>
          </div>
        </div>

        <div class="row">
          <div class="col box creator-box cr-3">
            <h1>03</h1>
            <p>Save tickets to Google or Apple Wallet for easy access.</p>
          </div>
          <div class="col box creator-box cr-4">
            <h1>04</h1>
            <p>
              Enhance your event experience with NFTs and community-based
              activities.
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 creator-car showMob">
        <h1>ARE YOU AN EVENT ORGANIZER?</h1>
        <div class="car-cnt">
          <carousel
            :perPage="1"
            :centerMode="true"
            :paginationEnabled="false"
            :navigationEnabled="true"
            navigationNextLabel=""
            navigationPrevLabel=""
          >
            <slide>
              <div class="slide-mob-cnt">
                <h1>01</h1>
                <p>Fill out the form to be accepted as an event organizer</p>
                <a href="/#/apply">
                  <button class="btn">APPLY</button>
                </a>
              </div>
            </slide>
            <slide>
              <div class="slide-mob-cnt">
                <h1>02</h1>
                <p>
                  Wait for the confirmation e-mail from our team, in the mean
                  while feel free to join discord.
                </p>
                <a href="https://discord.com/invite/PZAxKZkX9A" target="_blank">
                  <button class="btn">DISCORD</button>
                </a>
              </div>
            </slide>
            <slide>
              <div class="slide-mob-cnt">
                <h1>03</h1>
                <p>
                  Once accepted start creating your event using the admin panel.
                </p>
              </div>
            </slide>
            <slide>
              <div class="slide-mob-cnt">
                <h1>04</h1>
                <p>Share the event!</p>
                <a href="mailto:info@mego.tickets" target="_blank">
                  <button class="btn">ASK US TO SPONSOR THE EVENT</button>
                </a>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
      <div class="col-12 user-car showMob">
        <h1>ARE YOU A USER?</h1>
        <div class="car-cnt">
          <carousel
            :perPage="1"
            :centerMode="true"
            :paginationEnabled="false"
            :navigationEnabled="true"
            navigationNextLabel=""
            navigationPrevLabel=""
          >
            <slide>
              <div class="slide-mob-cnt">
                <h1>01</h1>
                <p>
                  Sign up with your wallet or create an internal wallet using
                  your email.
                </p>
              </div>
            </slide>
            <slide>
              <div class="slide-mob-cnt">
                <h1>02</h1>
                <p>Buy tickets with your preferred payment method.</p>
              </div>
            </slide>
            <slide>
              <div class="slide-mob-cnt">
                <h1>03</h1>
                <p>Save tickets to Google or Apple Wallet for easy access.</p>
              </div>
            </slide>
            <slide>
              <div class="slide-mob-cnt">
                <h1>04</h1>
                <p>
                  Enhance your event experience with NFTs and community-based
                  activities.
                </p>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
    </section>

    <!-- <section class="txt-alone ps-5 mb-0">
      <h1 class="new-txt-alone">ELEVATE YOUR EVENT EXPERIENCE!</h1>
      <p>
        Upgrade with MEGO's seamless ticketing platform.<br />
        Get started!
      </p>
            <a href="https://demo.mego.cx/#/" target="_blank">
        <button class="btn txt-alone-btn">JOIN NOW</button>
      </a>
    </section> -->

    <marquee-text :repeat="10" :duration="30">
      <p class="gradient-text marquee-txt">
        <span>ARE YOU CURIOUS?</span><span> ARE YOU CURIOUS?</span
        ><span> ARE YOU CURIOUS?</span>&nbsp;
      </p>
    </marquee-text>
    <section class="txt-alone">
      <h1 class="px-5">
        Are you figuring out if it could be<br />
        the right platform for you?<br />
        Just a few examples of how<br />
        MEGO can be used.
      </h1>
    </section>
    <Accordion />

    <section class="free-events">
      <h1>
        CREATE YOUR
        <span class="gradient-text">FREE EVENTS</span> <br />
        FOR FREE
      </h1>
      <p class="text-center">Only 8% to sell paid tickets + € 0.80 payments fees</p>

      <div class="row d-flex btn-cnt">
        <a href="https://join.mego.tickets/" target="_blank">
          <button class="btn mt-3">JOIN MEGO</button>
        </a>
      </div>

      <div class="row">
        <div class="col d-flex ticket-cnt">
          <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
            <path
              d="M14.7962 28.1354C22.5656 28.1354 28.8639 21.8371 28.8639 14.0677C28.8639 6.29832 22.5656 0 14.7962 0C7.02683 0 0.728516 6.29832 0.728516 14.0677C0.728516 21.8371 7.02683 28.1354 14.7962 28.1354Z"
              fill="var(--accentColor)"
            />
            <path
              d="M20.7571 10.0224L20.3974 10.3698C20.4661 10.4409 20.5074 10.5406 20.5074 10.6478C20.5074 10.7549 20.4662 10.8545 20.3976 10.9256C20.3976 10.9256 20.3975 10.9257 20.3974 10.9258L13.3928 18.1653L13.3924 18.1656C13.3234 18.2371 13.2334 18.2746 13.1428 18.2756C13.0539 18.2752 12.9653 18.2387 12.8972 18.1687C12.8971 18.1686 12.897 18.1685 12.8969 18.1684L9.60093 14.7628C9.60084 14.7627 9.60075 14.7626 9.60067 14.7625C9.53169 14.6908 9.49023 14.5905 9.49023 14.4826C9.49023 14.3748 9.53163 14.2745 9.60051 14.2028C9.66905 14.1322 9.75836 14.0956 9.84789 14.0956C9.93758 14.0956 10.027 14.1323 10.0956 14.2031C10.0956 14.2031 10.0956 14.2031 10.0956 14.2031L12.7849 16.9819L13.1442 17.3531L13.5035 16.9819L19.9023 10.3702C19.9023 10.3702 19.9023 10.3702 19.9023 10.3702C19.9709 10.2993 20.0604 10.2627 20.1501 10.2627C20.2398 10.2627 20.3292 10.2994 20.3978 10.3702L20.7571 10.0224ZM20.7571 10.0224C20.5961 9.85613 20.3777 9.7627 20.1501 9.7627C19.9224 9.7627 19.7041 9.85613 19.5431 10.0224L20.7571 11.2732C20.9174 11.1072 21.0074 10.8823 21.0074 10.6478C21.0074 10.4133 20.9174 10.1884 20.7571 10.0224Z"
              fill="black"
              stroke="black"
            />
          </svg>
          <p>Give multiple benefits for each ticket</p>
        </div>
        <div class="col d-flex ticket-cnt">
          <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
            <path
              d="M14.7962 28.1354C22.5656 28.1354 28.8639 21.8371 28.8639 14.0677C28.8639 6.29832 22.5656 0 14.7962 0C7.02683 0 0.728516 6.29832 0.728516 14.0677C0.728516 21.8371 7.02683 28.1354 14.7962 28.1354Z"
              fill="var(--accentColor)"
            />
            <path
              d="M20.7571 10.0224L20.3974 10.3698C20.4661 10.4409 20.5074 10.5406 20.5074 10.6478C20.5074 10.7549 20.4662 10.8545 20.3976 10.9256C20.3976 10.9256 20.3975 10.9257 20.3974 10.9258L13.3928 18.1653L13.3924 18.1656C13.3234 18.2371 13.2334 18.2746 13.1428 18.2756C13.0539 18.2752 12.9653 18.2387 12.8972 18.1687C12.8971 18.1686 12.897 18.1685 12.8969 18.1684L9.60093 14.7628C9.60084 14.7627 9.60075 14.7626 9.60067 14.7625C9.53169 14.6908 9.49023 14.5905 9.49023 14.4826C9.49023 14.3748 9.53163 14.2745 9.60051 14.2028C9.66905 14.1322 9.75836 14.0956 9.84789 14.0956C9.93758 14.0956 10.027 14.1323 10.0956 14.2031C10.0956 14.2031 10.0956 14.2031 10.0956 14.2031L12.7849 16.9819L13.1442 17.3531L13.5035 16.9819L19.9023 10.3702C19.9023 10.3702 19.9023 10.3702 19.9023 10.3702C19.9709 10.2993 20.0604 10.2627 20.1501 10.2627C20.2398 10.2627 20.3292 10.2994 20.3978 10.3702L20.7571 10.0224ZM20.7571 10.0224C20.5961 9.85613 20.3777 9.7627 20.1501 9.7627C19.9224 9.7627 19.7041 9.85613 19.5431 10.0224L20.7571 11.2732C20.9174 11.1072 21.0074 10.8823 21.0074 10.6478C21.0074 10.4133 20.9174 10.1884 20.7571 10.0224Z"
              fill="black"
              stroke="black"
            />
          </svg>
          <p>Choose your preferred payment method</p>
        </div>
        <div class="col d-flex ticket-cnt">
          <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
            <path
              d="M14.7962 28.1354C22.5656 28.1354 28.8639 21.8371 28.8639 14.0677C28.8639 6.29832 22.5656 0 14.7962 0C7.02683 0 0.728516 6.29832 0.728516 14.0677C0.728516 21.8371 7.02683 28.1354 14.7962 28.1354Z"
              fill="var(--accentColor)"
            />
            <path
              d="M20.7571 10.0224L20.3974 10.3698C20.4661 10.4409 20.5074 10.5406 20.5074 10.6478C20.5074 10.7549 20.4662 10.8545 20.3976 10.9256C20.3976 10.9256 20.3975 10.9257 20.3974 10.9258L13.3928 18.1653L13.3924 18.1656C13.3234 18.2371 13.2334 18.2746 13.1428 18.2756C13.0539 18.2752 12.9653 18.2387 12.8972 18.1687C12.8971 18.1686 12.897 18.1685 12.8969 18.1684L9.60093 14.7628C9.60084 14.7627 9.60075 14.7626 9.60067 14.7625C9.53169 14.6908 9.49023 14.5905 9.49023 14.4826C9.49023 14.3748 9.53163 14.2745 9.60051 14.2028C9.66905 14.1322 9.75836 14.0956 9.84789 14.0956C9.93758 14.0956 10.027 14.1323 10.0956 14.2031C10.0956 14.2031 10.0956 14.2031 10.0956 14.2031L12.7849 16.9819L13.1442 17.3531L13.5035 16.9819L19.9023 10.3702C19.9023 10.3702 19.9023 10.3702 19.9023 10.3702C19.9709 10.2993 20.0604 10.2627 20.1501 10.2627C20.2398 10.2627 20.3292 10.2994 20.3978 10.3702L20.7571 10.0224ZM20.7571 10.0224C20.5961 9.85613 20.3777 9.7627 20.1501 9.7627C19.9224 9.7627 19.7041 9.85613 19.5431 10.0224L20.7571 11.2732C20.9174 11.1072 21.0074 10.8823 21.0074 10.6478C21.0074 10.4133 20.9174 10.1884 20.7571 10.0224Z"
              fill="black"
              stroke="black"
            />
          </svg>
          <p>Unlock a multitude of advantages with just one ticket</p>
        </div>
        <div class="col d-flex ticket-cnt">
          <svg width="29" height="29" viewBox="0 0 29 29" fill="none">
            <path
              d="M14.7962 28.1354C22.5656 28.1354 28.8639 21.8371 28.8639 14.0677C28.8639 6.29832 22.5656 0 14.7962 0C7.02683 0 0.728516 6.29832 0.728516 14.0677C0.728516 21.8371 7.02683 28.1354 14.7962 28.1354Z"
              fill="var(--accentColor)"
            />
            <path
              d="M20.7571 10.0224L20.3974 10.3698C20.4661 10.4409 20.5074 10.5406 20.5074 10.6478C20.5074 10.7549 20.4662 10.8545 20.3976 10.9256C20.3976 10.9256 20.3975 10.9257 20.3974 10.9258L13.3928 18.1653L13.3924 18.1656C13.3234 18.2371 13.2334 18.2746 13.1428 18.2756C13.0539 18.2752 12.9653 18.2387 12.8972 18.1687C12.8971 18.1686 12.897 18.1685 12.8969 18.1684L9.60093 14.7628C9.60084 14.7627 9.60075 14.7626 9.60067 14.7625C9.53169 14.6908 9.49023 14.5905 9.49023 14.4826C9.49023 14.3748 9.53163 14.2745 9.60051 14.2028C9.66905 14.1322 9.75836 14.0956 9.84789 14.0956C9.93758 14.0956 10.027 14.1323 10.0956 14.2031C10.0956 14.2031 10.0956 14.2031 10.0956 14.2031L12.7849 16.9819L13.1442 17.3531L13.5035 16.9819L19.9023 10.3702C19.9023 10.3702 19.9023 10.3702 19.9023 10.3702C19.9709 10.2993 20.0604 10.2627 20.1501 10.2627C20.2398 10.2627 20.3292 10.2994 20.3978 10.3702L20.7571 10.0224ZM20.7571 10.0224C20.5961 9.85613 20.3777 9.7627 20.1501 9.7627C19.9224 9.7627 19.7041 9.85613 19.5431 10.0224L20.7571 11.2732C20.9174 11.1072 21.0074 10.8823 21.0074 10.6478C21.0074 10.4133 20.9174 10.1884 20.7571 10.0224Z"
              fill="black"
              stroke="black"
            />
          </svg>
          <p>Seamlessly store all your tickets with just one click</p>
        </div>
      </div>
      <!-- <div class="row text-center">
        <a href="https://demo.mego.cx/#/" target="_blank">
          <button class="btn">DISCOVER MORE</button></a
        >
      </div> -->
    </section>

    <!-- <section class="txt-alone ps-5" style="margin: 5rem 0">
      <h1 class="new-txt-alone">EXCLUSIVE ACCESS!</h1>
      <p>
        Unlock VIP perks with MEGO NFT tickets.
        <br />
        Act now!
      </p>
       <a href="https://demo.mego.cx/#/" target="_blank">
        <button class="btn txt-alone-btn">JOIN NOW</button>
      </a>
    </section> -->

    <Multichain class="mt-5" />
    <div class="row text-center req-btn">
      <a style="position: relative; z-index: 99;" href="mailto:info@mego.tickets">
        <button class="btn txt-alone-btn">REQUEST A DEMO</button>
      </a>
    </div>

    <marquee-text :repeat="14" :duration="20" :reverse="true">
      <p class="gradient-text marquee-txt">
        <span>OUR VISION </span><span>OUR VISION </span
        ><span>OUR VISION</span> &nbsp;
      </p>
    </marquee-text>

    <section class="txt-alone">
      <h1 class="px-5 txt-end">
        We want to create<br />
        a revolutionary platform. <br />
        Follow our path to see how <br />an idea is turned into reality.
      </h1>

      <!-- <Roadmap /> -->
    </section>
    <!--  <section class="txt-alone ps-5 mb-5">
      <h1 class="new-txt-alone">CERTIFIED TICKETS!</h1>
      <p>
        Follow the first movers and experience secure<br />
        events with certified NFT tickets.
      </p>
      
    </section> -->

    <section class="partners text-center pt-5">
      <h1>Our platform has already been used by:</h1>
      <div class="parts d-flex">
        <div class="partner-item">
          <div
            class="filter"
            :class="{
              'dark-filter-part': userTheme === 'dark-theme',
              'light-filter-part': userTheme === 'light-theme',
            }"
          ></div>
          <img
            :src="
              userTheme === 'dark-theme'
                ? darkPartnerDenver
                : lightPartnerDenver
            "
          />
        </div>
        <a href="https://nftberlin.org/#/" target="_blank">
          <div
            class="filter"
            :class="{
              'dark-filter-part': userTheme === 'dark-theme',
              'light-filter-part': userTheme === 'light-theme',
            }"
          ></div>
          <img src="../assets/img/partner_berlin.svg" />
        </a>
        <a href="https://hnft.splashthat.com/" target="_blank">
          <div
            class="filter"
            :class="{
              'dark-filter-part': userTheme === 'dark-theme',
              'light-filter-black-part': userTheme === 'light-theme',
            }"
          ></div>
          <img
            :src="
              userTheme === 'dark-theme' ? darkPartnerHnft : lightPartnerHnft
            "
          />
        </a>
        <a href="https://www.spaghett-eth.com/" target="_blank">
          <div
            class="filter"
            :class="{
              'dark-filter-part': userTheme === 'dark-theme',
              'light-filter-part': userTheme === 'light-theme',
            }"
          ></div>
          <img
            :src="
              userTheme === 'dark-theme' ? darkPartnerSpagh : lightPartnerSpagh
            "
          />
        </a>
        <a href="https://ethna.rocks/" target="_blank">
          <div
            class="filter"
            :class="{
              'dark-filter-part': userTheme === 'dark-theme',
              'light-filter-part': userTheme === 'light-theme',
            }"
          ></div>
          <img
            :src="
              userTheme === 'dark-theme' ? lightPartnerEthna : lightPartnerEthna
            "
          />
        </a>
        <a href="https://napuleth.org/" target="_blank">
          <div
            class="filter"
            :class="{
              'dark-filter-part': userTheme === 'dark-theme',
              'light-filter-part': userTheme === 'light-theme',
            }"
          ></div>
          <img
            :src="
              userTheme === 'dark-theme' ? lightPartnerNapulETH : lightPartnerNapulETH
            "
          />
        </a>
        <a href="https://www.ethrome.org/" target="_blank">
          <div
            class="filter"
            :class="{
              'dark-filter-part': userTheme === 'dark-theme',
              'light-filter-part': userTheme === 'light-theme',
            }"
          ></div>
          <img
            :src="
              userTheme === 'dark-theme' ? lightPartnerRomETH : lightPartnerRomETH
            "
          />
        </a>
        <div class="partner-item">
          <div
            class="filter"
            :class="{
              'dark-filter-part': userTheme === 'dark-theme',
              'light-filter-black-part': userTheme === 'light-theme',
            }"
          ></div>
          <img
            :src="
              userTheme === 'dark-theme' ? darkPartnerRome : lightPartnerRome
            "
          />
        </div>
        <a href="https://www.ethmilan.xyz/" target="_blank">
          <div
            class="filter"
            :class="{
              'dark-filter-part': userTheme === 'dark-theme',
              'light-filter-black-part': userTheme === 'light-theme',
            }"
          ></div>
          <img
            :src="
              userTheme === 'dark-theme'
                ? darkPartnerEthMil
                : lightPartnerEthMil
            "
          />
        </a>
        <a href="https://stablesummit.xyz/" target="_blank">
          <div
            class="filter"
            :class="{
              'dark-filter-part': userTheme === 'dark-theme',
              'light-filter-black-part': userTheme === 'light-theme',
            }"
          ></div>
          <img
            :src="
              userTheme === 'dark-theme'
                ? darkPartnerSummit
                : lightPartnerSummit
            "
          />
        </a>
      </div>

      <a href="mailto:info@mego.tickets">
        <button class="btn" style="padding: 13px 25px">
          ASK US TO SPONSOR YOUR EVENT
        </button>
      </a>
    </section>
    <div class="px-5 foot-cnt">
      <Footer
        :class="{
          'dark-opacity-footer': userTheme === 'dark-theme',
          'light-opacity-footer': userTheme === 'light-theme',
        }"
      />
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Accordion from "@/components/Accordion.vue";
//import Roadmap from "@/components/Roadmap.vue";
import UseMego from "@/components/UseMego.vue";
import Multichain from "@/components/Multichain.vue";
import Footer from "@/components/Footer.vue";

import { Carousel, Slide } from "vue-carousel";
import MarqueeText from "vue-marquee-text-component";

export default {
  name: "Home",
  components: {
    Navbar,
    Accordion,
    Carousel,
    UseMego,
    //Roadmap,
    Multichain,
    Footer,
    Slide,
    MarqueeText,
  },

  data() {
    return {
      loading: false,
      userTheme: "dark-theme",
      darkImageHome: "/blob-grad-home-comp.png",
      lightImageHome: "/blob-grad-home-light.png",
      darkMegoGif: "/mego_bubble.gif",
      lightMegoGif: "/mego_bubble_light_comp.gif",
      /* */
      /* partners images dark & light themes */
      /* */
      darkPartnerDenver: "/partner_denver.png",
      lightPartnerDenver: "/partner_denver_light.png",
      darkPartnerHnft: "/partner_hnft.png",
      lightPartnerHnft: "/partner_hnft_light.png",
      darkPartnerSpagh: "/partner_spagh.png",
      lightPartnerSpagh: "/partner_spagh_light.png",
      darkPartnerRome: "/partner_rome.png",
      lightPartnerRome: "/partner_rome_light.png",
      darkPartnerEthMil: "/partner_ethmilan.png",
      lightPartnerEthMil: "/partner_ethmilan.png",
      darkPartnerSummit: "/partner_summit.png",
      lightPartnerSummit: "/partner_summit.png",
      lightPartnerEthna: "/lightPartnerEthna.svg",
      lightPartnerRomETH: "/ethrome-logo.png",
      lightPartnerNapulETH: "/partner_NapulEth.svg",
    };
  },
  mounted() {
    const initUserTheme = this.getTheme() || this.getMediaPreference();
    this.setTheme(initUserTheme);
  },

  methods: {
    /* >>>> THEME SETTINGS <<<< */
    setTheme(theme) {
      localStorage.setItem("user-theme", theme);
      this.userTheme = theme;
      document.documentElement.className = theme;
    },
    getTheme() {
      return localStorage.getItem("user-theme");
    },
    getMediaPreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "dark-theme";
      }
    },

    toggleMoon() {
      this.isActive = !this.isActive;
      this.loading = true;
      document.querySelector("body").style.overflowY = "hidden";
      document.querySelector("body").style.position = "fixed";
      document.querySelector("body").style.top = `-${this.scrollPosition}px`;

      setTimeout(() => {
        this.loading = false;
        document.querySelector("#loader").style.display = "none";
      }, 2200);

      setTimeout(() => {
        const activeTheme = localStorage.getItem("user-theme");
        if (activeTheme === "dark-theme") {
          this.setTheme("dark-theme");
        } else {
          this.setTheme("dark-theme");
        }
      }, 1000);

      setTimeout(() => {
        document.querySelector("body").style.overflowY = "scroll";
        document.querySelector("body").style.removeProperty("position");
        document.querySelector("body").style.removeProperty("top");
      }, 2250);
    },
  },
};
</script>
